import React from 'react';
import Head from 'next/head';

function Font() {
  return (
    <Head>
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    </Head>
  );
}
export default Font;
